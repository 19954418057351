import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams, HttpHeaders} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  public data : any;

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { 
    
  }

  //listagem de noticias --> ok
  lista_noticias(): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/noticias')
      .pipe(
          tap(_ => console.log('Listagem de Noticias'))
      );
  }

  //pesquisa de noticia especifica --> ok
  detalha_noticias(noticia : any): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/noticias/'+noticia)
      .pipe(
          tap(_ => console.log('Pesquisa de Noticia Especifica'))
      );
  }  

}
