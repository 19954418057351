import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PostEventComponent } from './components/post-event/post-event.component';
import { PostLevelsComponent } from './components/post-levels/post-levels.component';
import { PostNewsComponent } from './components/post-news/post-news.component';
import { PostStoresComponent } from './components/post-stores/post-stores.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full'
  },
  {
    path: 'altera-cadastro/:id',
    loadChildren: () => import('./altera-cadastro/altera-cadastro.module').then( m => m.AlteraCadastroPageModule)
  },
  {
    path: 'altera-cadastro-lojista',
    loadChildren: () => import('./altera-cadastro-lojista/altera-cadastro-lojista.module').then( m => m.AlteraCadastroLojistaPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then( m => m.CadastroPageModule)
  },
  {
    path: 'cadastro-lojista',
    loadChildren: () => import('./cadastro-lojista/cadastro-lojista.module').then( m => m.CadastroLojistaPageModule)
  },
  {
    path: 'consulta-pontos',
    loadChildren: () => import('./consulta-pontos/consulta-pontos.module').then( m => m.ConsultaPontosPageModule)
  },
  {
    path: 'esqueceu-senha',
    loadChildren: () => import('./esqueceu-senha/esqueceu-senha.module').then( m => m.EsqueceuSenhaPageModule)
  },
  {
    path: 'eventos',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'index',
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'lojas-participantes',
    loadChildren: () => import('./lojas-participantes/lojas-participantes.module').then( m => m.LojasParticipantesPageModule)
  },
  {
    path: 'meu-cadastro',
    loadChildren: () => import('./meu-cadastro/meu-cadastro.module').then( m => m.MeuCadastroPageModule)
  },
  {
    path: 'politica-participacao',
    loadChildren: () => import('./politica-participacao/politica-participacao.module').then( m => m.PoliticaParticipacaoPageModule)
  },
  {
    path: 'premios-detalhes',
    loadChildren: () => import('./premios-detalhes/premios-detalhes.module').then( m => m.PremiosDetalhesPageModule)
  },
  {
    path: 'recupera-cadastro',
    loadChildren: () => import('./recupera-cadastro/recupera-cadastro.module').then( m => m.RecuperaCadastroPageModule)
  },
  {
    path: 'alterar-senha',
    loadChildren: () => import('./alterar-senha/alterar-senha.module').then( m => m.AlterarSenhaPageModule)
  },
  {
    path: 'evento/:id',
    component: PostEventComponent
  },
  {
    path: 'level/:slug',
    component: PostLevelsComponent
  },
  {
    path: 'loja/:id',
    component: PostStoresComponent
  },
  {
    path: 'noticias',
    loadChildren: () => import('./noticias/noticias.module').then( m => m.NoticiasPageModule)
  },
  {
    path: 'noticia/:id',
    component: PostNewsComponent
  },
  {
    path: 'usuario',
    loadChildren: () => import('./usuario/usuario.module').then( m => m.UsuarioPageModule)
  },
  {
    path: 'resgatar-premio/:id',
    loadChildren: () => import('./resgatar-premio/resgatar-premio.module').then( m => m.ResgatarPremioPageModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'socios',
    loadChildren: () => import('./socios/socios.module').then( m => m.SociosPageModule)
  },
  {
    path: 'cad-socios',
    loadChildren: () => import('./cad-socios/cad-socios.module').then( m => m.CadSociosPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
