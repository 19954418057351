import { IonicModule, ToastController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Location } from '@angular/common';

import { PremiosService } from 'src/app/services/premios/premios.service';

@Component({
  selector: 'app-post-levels',
  templateUrl: './post-levels.component.html',
  styleUrls: ['./post-levels.component.scss'],
  providers: [PremiosService]
})
export class PostLevelsComponent implements OnInit {

  titulo    : string;
  retorno   : any;
  status    : string;
  categoria : string;

  slug      : string;
  slug1     : string;
  visible;

  public premio_detalhes  = [{"CODIGO":"", "TITULO":"","DESCRICAO":"","IMAGEM":"","PONTOS":""}];

  constructor(public toastCtrl : ToastController, public _location: Location, public premiosService : PremiosService, private route: ActivatedRoute) { 

    this.premio_detalhes = [];
    this.visible = false;

    this.route.params.subscribe(
      params => {
        this.slug = params['slug'];
        this.slug1 = this.slug;

        this.pesquisar_premios();

      }
    )
  }

  
  public pesquisar_premios() {
     

    this.premiosService.consulta_premios_categoria(this.slug1).subscribe(data => {

        this.retorno = data;
        console.log(this.retorno);

        this.status = this.retorno['status'];
        console.log(this.status);

        if (this.status === 'ok') {


            for(var i = 0; i < this.retorno['data'].length; i++) {
                this.premio_detalhes.push({"CODIGO":this.retorno['data'][i].premio_codigo,"TITULO":this.retorno['data'][i].premio_titulo,"DESCRICAO":this.retorno['data'][i].premio_descricao,
                                          "IMAGEM":this.retorno['data'][i].premio_foto,"PONTOS":this.retorno['data'][i].premio_pontos});
            }

        } else {

            this.status = this.retorno['data']['MENSAGEM'];
            this.mostrar(this.status);
        }
    });  
  }

  async mostrar(mensagem : string) {
    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: 2000,
      position: "bottom"
    });
  
    toast.present();
  }    

  ngOnInit() {

  }

  voltar() {
    this._location.back();
  }  

  getSlug() {
    
  }

  resgatar() {

  }

  getColorBySlug() {
    switch(this.slug) {
      case 'diamante':
       return '#6F83A5'
        break;
      case 'ouro':
        return '#E2C164';
        break;
      case 'prata':
        return  '#AFAFAF';
        break;
      case 'bronze':
        return '#F0894D';
        break;
    }
  }

  getImageBySlug() {
    switch(this.slug) {
      case 'diamante':
        return 'user-diamond'
        break;
      case 'ouro':
        return 'gold';
        break;
      case 'prata':
        return  'silver';
        break;
      case 'bronze':
        return  'bronze';
        break;
    }
  }

  getPointsBySlug() {
    switch(this.slug) {
      case 'diamante':
        return '3000000'
        break;
      case 'ouro':
        return '2000000';
        break;
      case 'prata':
        return  '1000000';
        break;
      case 'bronze':
        return  '500000';
        break;
      case 'bronze':
        return  '499999';
        break;

    }
  }


}
