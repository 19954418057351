import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';


import { FormBuilder, FormGroup, Validators, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { NavController, NavParams, MenuController, LoadingController, Platform, AlertController, ToastController  } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { LojasService } from '../../services/lojas/lojas.service';

@Component({
  selector: 'app-post-stores',
  templateUrl: './post-stores.component.html',
  styleUrls: ['./post-stores.component.scss'],
  providers: [LojasService]
})
export class PostStoresComponent implements OnInit {

  slug : string;

  retorno  : any;
  retorno1 : any;

  status  : string;
  status1 : string;

  public loja_nadd  = {"CODIGO":"","LOJA":"","IMAGEM":"","DESCRICAO":"","WHATASPP":""};

  constructor(public lojaService : LojasService, public _location: Location, private route: ActivatedRoute) { 


    


  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.slug = params['id'];
        console.log(this.slug);
        this.consulta_cad_loja();
      }
    )
  }

  consulta_cad_loja() {

    this.lojaService.consulta_cad_loja(this.slug).subscribe(data => {

      this.retorno = data;

      this.status = this.retorno['status'];

      if (this.status === 'ok') {
          for(var i = 0; i < this.retorno['data'].length; i++) {

              this.loja_nadd.CODIGO    = this.retorno['data'][i].lojap_codigo;
              this.loja_nadd.LOJA      = this.retorno['data'][i].lojap_titulo;
              this.loja_nadd.IMAGEM    = this.retorno['data'][i].lojap_foto;
              this.loja_nadd.DESCRICAO = this.retorno['data'][i].lojap_descricao;


          }

      } else {

          this.status = this.retorno['data']['MENSAGEM'];
      }

    });

  }



  voltar() {
    this._location.back();
  }
}
