import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { EventosService } from '../../services/eventos/eventos.service';
import { NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post-event',
  templateUrl: './post-event.component.html',
  styleUrls: ['./post-event.component.scss'],
  providers: [EventosService]
})
export class PostEventComponent implements OnInit {

  titulo    : string;
  retorno   : any;
  status    : string;
  categoria : string;

  titulo1    : string;
  retorno1   : any;
  status1    : string;
  categoria1 : string;
  mensagem   : string;

  slug      : string;
  slug1     : string;

  public evento_detalhes  = {"CODIGO":"","TITULO":"","SUBTITULO":"","DESCRICAO":"","IMAGEM":"","LOCAL":"","DATA":"","FOTOS":"","MAPS":""};  
  public fotos_evento = [{"FOTO":""}];

  currentImageLightBox = this.fotos_evento[0].FOTO;
  showMask = false;
  previewImage = false;

  constructor(private _location: Location, public toastCtrl: ToastController, public eventoService : EventosService, public navCtrl: NavController, 
    private route: ActivatedRoute) { 

  

  }

  async mostrar(mensagem : string) {
    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: 2000,
      position: "bottom"
    });
  
    toast.present();
  }  


  detalhe_evento() {

    this.fotos_evento = [];
    console.log(this.slug);
    this.eventoService.detalha_evento(this.slug).subscribe(data => {

        this.retorno = data;
        this.status  = this.retorno['status'];
        console.log(this.status);

        if (this.status === 'ok') {


            for(var i = 0; i < this.retorno['data'].length; i++) {
                
                this.evento_detalhes.CODIGO    = this.retorno['data'][i].evento_codigo;
                this.evento_detalhes.TITULO    = this.retorno['data'][i].evento_titulo;
                this.evento_detalhes.SUBTITULO = this.retorno['data'][i].evento_subtitulo;
                this.evento_detalhes.DESCRICAO = this.retorno['data'][i].evento_descricao;
                this.evento_detalhes.IMAGEM    = this.retorno['data'][i].evento_foto;
                this.evento_detalhes.LOCAL     = this.retorno['data'][i].evento_local;
                this.evento_detalhes.DATA      = this.retorno['data'][i].evento_foto;
                this.evento_detalhes.FOTOS     = this.retorno['data'][i].evento_linkfotos;
                this.evento_detalhes.MAPS      = this.retorno['data'][i].evento_linkmaps;
            }

            this.eventoService.recupera_galeria(this.slug).subscribe(data1 => {

              this.retorno1 = data1;
              this.status1  = this.retorno1['status'];
              console.log(this.status);
      
              if (this.status1 === 'ok') {
      
      
                for(var i = 0; i < this.retorno1['data'].length; i++) {
                     this.fotos_evento.push({"FOTO":this.retorno1['data'][i].GALFOTOS_FOTO});
                  }
                console.log(this.fotos_evento);
      

      
              } else {
      
                  this.mensagem = this.retorno1['data']['MENSAGEM'];
                  this.mostrar(this.mensagem);
              }
            });    
      

        } else {

            this.mensagem = this.retorno['data']['MENSAGEM'];
            this.mostrar(this.status);
        }
      });    

  }


  voltar() {
    this.navCtrl.navigateRoot('/home');
  }

  handleLightbox(index: number): void {
    this.currentImageLightBox = this.fotos_evento[index].FOTO;
    console.log("meu teste" + this.evento_detalhes)
    this.showMask = true;
    this.previewImage = true;
  }

  closeLightbox(): void {
    this.showMask = false;
    this.previewImage = false;
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.slug  = params['id'];
        this.slug1 = this.slug;
        this.detalhe_evento();
      }
    )  
  }

  acessar() {
    
  }

}
