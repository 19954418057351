import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { NavController, NavParams, MenuController, LoadingController, AlertController, ToastController  } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public selectedIndex = 0;

  usuario   : string;
  senha     : string;
  coduser   : string;
  deviceId  : any;

  public appPages = [
    { title: 'Sócios', url: 'socios', icon: '../../assets/icon/gem.png' },
    { title: 'Meus Pontos', url: 'consulta-pontos', icon: '../../assets/icon/gem.png' },
    { title: 'Eventos', url: 'eventos', icon: '../../assets/icon/calendar-date.png' },
    { title: 'Prêmios', url: 'premios-detalhes', icon: '../../assets/icon/gift-fill.png' },
    { title: 'Notícias', url: 'noticias', icon: '../../assets/icon/newspaper.png' },
    { title: 'Marcas', url: 'lojas-participantes', icon: '../../assets/icon/bookmarks-fill.png' },
    { title: 'Regulamento', url: 'politica-participacao', icon: '../../assets/icon/book-half.png' },
    { title: 'Fale conosco', url: 'contato', icon: '../../assets/icon/chat-right-dots-fill.png' },    
  ];

  
 constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, 
             public storage: Storage, public background : BackgroundMode,
             public nav: NavController, public menu: MenuController) {

    
    this.criaStorage();
    this.initializeApp();
    this.backButtonEvent();

 }

  backButtonEvent() {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribeWithPriority(99999, () => {
        navigator['app'].exitApp();
      });
    } else {
      this.nav.navigateRoot("/index");
    }
  }    
  
  logout() {
    this.storage.clear();  
    if (this.platform.is('android')) {
      this.nav.navigateRoot("/index");
    } else {
      this.nav.navigateRoot("/index");
    }
  }  

  async criaStorage() {
    await this.storage.create();
    
  }

  initializeApp() {
      
    this.platform.ready().then(async () => {
        
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
        
        //this.oneSignal.startInit('125e3690-03e2-4f8b-b204-60385170d8da','362744573184');
        OneSignal.setAppId("125e3690-03e2-4f8b-b204-60385170d8da");
        OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
          console.log("User accepted notifications: " + accepted);
        });
    
        OneSignal.setNotificationOpenedHandler(function(jsonData) {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          alert(jsonData.notification.body.toString());
        });

        OneSignal.setNotificationWillShowInForegroundHandler(function(notificationReceivedEvent) {
          notificationReceivedEvent.complete(notificationReceivedEvent.getNotification());
        });

        var iamClickCallback = function(jsonData) {
          var iamClickAction = JSON.stringify(jsonData);
          console.log('iamClickCallback: ' + iamClickAction);
        };
        OneSignal.setInAppMessageClickHandler(iamClickCallback);

        /*(OneSignal as any).getDeviceState(async (status: DeviceState) => {
          this.deviceId = status.userId;
          await this.storage.set("deviceId_app",this.deviceId);
        });*/

        

         this.storage.get('coduser_app').then((val) => {
          this.coduser = val;
    
          this.storage.get('user_app').then((val) => {
              this.usuario = val;
    
              if (this.usuario != undefined) {
                  this.nav.navigateRoot('/home');
              } else {
                  this.nav.navigateRoot('/index');
              }
          });
        })   

         this.background.on('activate').subscribe(() => {

         });         

    });

    
   

  }

  ngOnInit() {
    this.selectedIndex = this.appPages.findIndex(page => page.url);
  }  
}
