import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams, HttpHeaders} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  public data : any;

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { 
    
  }

  //consulta eventos --> ok
  consulta_eventos(): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/eventos')
      .pipe(
          tap(_ => console.log('Consulta - Eventos'))
      );
  }

  //pesquisa de evento especifico --> ok
  detalha_evento(evento): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/eventos/'+evento)
      .pipe(
          tap(_ => console.log('Pesquisa de Evento Especifico'))
      );
  }  
  
  //recupera galeria de fotos --> ok
  recupera_galeria(evento): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/fotos/'+evento)
      .pipe(
          tap(_ => console.log('Galeria de Fotos'))
      );
  }   

}
