import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Location } from '@angular/common';

import { NoticiasService } from '../../services/noticias/noticias.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post-news',
  templateUrl: './post-news.component.html',
  styleUrls: ['./post-news.component.scss'],
  providers: [NoticiasService]
})
export class PostNewsComponent implements OnInit {

  titulo    : string;
  retorno   : any;
  status    : string;
  categoria : string;

  slug      : string;
  slug1     : string;

  public lista_noticias  = {"CODIGO":"","TITULO":"", "SUBTITULO":"","TEXTO":"","IMAGEM":""};  

  constructor(private _location: Location, public toastCtrl: ToastController, public noticiasService : NoticiasService, public navCtrl: NavController, 
    private route: ActivatedRoute) { 

    this.route.params.subscribe(
      params => {
        this.slug  = params['id'];
        this.slug1 = this.slug;
        this.detalhe_noticia();

      }
    )
  }

  async mostrar(mensagem : string) {
    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: 2000,
      position: "bottom"
    });
  
    toast.present();
  }  

  detalhe_noticia() {

      this.noticiasService.detalha_noticias(this.slug).subscribe(data => {

        this.retorno = data;
        this.status  = this.retorno['status'];
        console.log(this.status);

        if (this.status === 'ok') {


            for(var i = 0; i < this.retorno['data'].length; i++) {
                this.lista_noticias.CODIGO    = this.retorno['data'][i].NOTICIA_CODIGO;
                this.lista_noticias.TITULO    = this.retorno['data'][i].NOTICIA_TITULO;
                this.lista_noticias.SUBTITULO = this.retorno['data'][i].NOTICIA_SUBTITULO;
                this.lista_noticias.TEXTO     = this.retorno['data'][i].NOTICIA_TEXTO;
                this.lista_noticias.IMAGEM    = this.retorno['data'][i].NOTICIA_FOTO;
            }

        } else {

            this.status = this.retorno['data']['MENSAGEM'];
            this.mostrar(this.status);
        }
      });  

  }

  ngOnInit() {

  }

  voltar() {
    this._location.back();
  }
}
