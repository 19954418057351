import { map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams, HttpHeaders} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PremiosService {

  public data : any;

  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { 
    
  }

  //consulta categ premios --> ok
  consulta_categpremios(): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/categpremios')
      .pipe(
          tap(_ => console.log('Consulta - Categ. Prêmios'))
      );
  }
  
  //consulta nova categ premios --> ok
  consulta_nova_categpremios(): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/nova-categpremios')
      .pipe(
          tap(_ => console.log('Consulta - Nova Categ. Prêmios'))
      );
  }

  //consulta premios --> ok
  consulta_premios(): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/premios')
      .pipe(
          tap(_ => console.log('Consulta - Prêmios'))
      );
  }

  //consulta premios --> ok
  consulta_premios_categoria(categ : string): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/premios/'+categ)
      .pipe(
          tap(_ => console.log('Consulta Prêmios da Categoria'))
      );
  }

   //detalhar premio -->ok
  detalhar_premio(codpremio : string): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/detalhar_premio/'+codpremio)
      .pipe(
          tap(_ => console.log('De'))
      );
  }  

  //resgatar premio -->
  resgatar_premios(premio : any): Observable<any[]> {
    return this.http.post<any[]>('https://api.nadd.com.br/api-mobile/resgatar_premio',premio,this.httpHeader)
      .pipe(
          tap(_ => console.log('Resgatar Premios'))
      );
  }  

  //mostrar historico -->
  historico_resgate_premios(usuario : string): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/resgatar_premio/'+usuario)
      .pipe(
          tap(_ => console.log('Histórico - Resgate Premios'))
      );
  }  
  
  //premios por pontos -->
  premios_pontos(pontos : string): Observable<any[]> {
    return this.http.get<any[]>('https://api.nadd.com.br/api-mobile/premios-pontos/'+pontos)
      .pipe(
          tap(_ => console.log('Histórico - Resgate Premios'))
      );
  }    

}
